const inputHeights = 30
const inputPaddings = '4px 8px'

const fieldStyle = (theme) => ({
  formControl: {
    width: '100%',
  },
  inLineFlexGrow: {
    flexGrow: 1,
    width: 'min-content',
  },
  selectPositionRelativeClass: {
    position: 'relative',

    '& svg': {
      position: 'absolute',
      right: 12,
      top: 12,
    },
  },

  // menu item inside the select paper
  menuItem: {
    boxSizing: 'border-box',
    padding: '0px 4px',
    '& .MuiTypography-body1': {
      fontSize: '13px !important',
    },
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
    '& .MuiChip-label': {
      fontSize: 10,
    },
  },

  // number inputs
  inputJustifyRight: {
    '& input': {
      textAlign: 'end !important',
    },
  },

  // date input full width
  datePickerInput: {
    cursor: 'pointer',
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
    '& .MuiFormControl-root': {
      cursor: 'default',
      '& .MuiOutlinedInput-startEnd': {
        paddingLeft: '0 !important',
      },
      '& .MuiInputBase-root': {
        flexGrow: 1,
        fontSize: 12,
        height: inputHeights,
        cursor: 'pointer',
        '& .MuiInputBase-input': {
          padding: inputPaddings,
          cursor: 'pointer',
        },
      },
    },
    '& fieldset': {
      boxShadow: '0 2px 6px 0 rgba(103, 103, 139, 0.1)',
    },
  },
  calendarIcon: {
    height: 18,
    width: 18,
    fill: theme.palette.secondary.main,
  },

  // error || helper text color
  formHelperText: {
    color: theme.palette.error.main,
    lineHeight: 1,
    marginTop: 2,
    fontSize: 10,
  },

  helperText: {
    '& .MuiFormHelperText-root': {
      lineHeight: 1,
      margin: '2px 8px 0px 8px',
      fontSize: 10,
    },
  },

  // loading indicator in select field
  loadingEndAdornment: {
    paddingRight: '10px',
    position: 'absolute',
    right: 0,
    backgroundColor: theme.palette.background.white,
    '& .MuiCircularProgress-root': {
      width: '18px !important',
      height: '18px !important',

      '& svg': {
        top: 'initial',
        right: 'initial',
      },
    },
  },
  loadingPaddingRight: {
    '& .MuiInputBase-root': {
      '& input': {
        paddingRight: 40,
      },
    },
  },
  selectIcon: {
    cursor: 'pointer',
    pointerEvents: 'none',
    '& svg': {
      position: 'absolute',
      right: 10,
      top: 'calc(50% - 6px)',
      width: 19,
      height: 12,
    },
  },
  selectInput: {
    position: 'relative',
    fontSize: 12,
    '& .MuiSelect-select': {
      height: inputHeights,
      padding: '4px 32px 4px 9px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& fieldset': {
      boxShadow: '0 2px 6px 0 rgba(103, 103, 139, 0.1)',
    },
  },

  emptyOption: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
  },

  fieldLabel: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 600,
    paddingBottom: 2,
    height: 18,
    boxSizing: 'border-box',
    lineHeight: 1.1,
  },

  inLineFieldLabel: {
    width: 120,
    minWidth: 120,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    paddingRight: 10,
    textAlign: 'end',
  },

  checkBoxControl: {
    '& .MuiCheckbox-root': {
      padding: 0,
      width: 30,
      height: 30,
    },
    '& .MuiFormHelperText-root': {
      lineHeight: '1.2',
      marginTop: 2,
    },

    '& svg': {
      width: 24,
      height: 24,
    },
  },

  flexGrowRow: {
    flexGrow: 1,
    flexDirection: 'row',
  },

  adornments: {
    '& .MuiInputBase-adornedStart': {
      paddingLeft: 0,
    },
    '& .MuiInputBase-adornedEnd': {
      paddingRight: 0,
    },
  },

  loginPageAdornment: {
    height: '40px !important',
    width: '40px !important',
  },

  inputPadding: {
    '& .MuiInputBase-input': {
      padding: '4px 9px',
      height: inputHeights,
      fontSize: 12,
      boxSizing: 'border-box',
    },
    '& input': {
      fontSize: 12,
    },
  },

  fieldsPadding: {
    '& .MuiGrid-grid-sm-3': {
      paddingLeft: 10,
    },
  },
  fieldsPaddingRight: {
    paddingRight: 10,
  },

  submitFormButton: {
    padding: inputPaddings,
    height: inputHeights,
    fontSize: 16,
    boxSizing: 'border-box',
  },
  submitFormButtonPosition: {
    paddingTop: 16,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },

  smallGridPadding: {
    '&:nth-child(odd)': {
      paddingRight: 10,
    },
    '&:nth-child(even)': {
      paddingLeft: 10,
    },
  },
  fieldEndAdornmentPadding: {
    '& input': {
      padding: '12px 0 12px 14px !important',
    },
  },
  endAdornmentPadding: {
    paddingRight: 12,
  },
  datePickerEndAdornmentPadding: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0 !important',
    },
  },

  // cursor pointer class
  cursorPointer: {
    cursor: 'pointer',
  },

  adornmentIcon: {
    width: inputHeights,
    height: inputHeights,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      fill: theme.palette.primary.dark,
      opacity: '.5',
      width: 18,
    },
  },

  adornment: {
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },

  fieldShadow: {
    '& fieldset': {
      boxShadow: '0 2px 6px 0 rgba(103, 103, 139, 0.1)',
    },
    /* change background color for autofilled fields by chrome */
    '& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active ':
      {
        boxShadow: '0 0 0 30px white inset !important',
      },
  },

  inputStyles: {
    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.background.white + ' !important',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.white,
    },
    '& .MuiOutlinedInput-multiline': {
      backgroundColor: theme.palette.background.white,
      fontSize: 12,
    },
    '&.MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.background.white + ' !important',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.text.secondary + 'ee',
      cursor: 'not-allowed',
    },
  },
  // styles for login texts (stay logged in, forgot password)
  loginText: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: 14,
    font: 'Inter',
  },
  loginButton: {
    height: '40px !important',
    fontSize: 14,
    textTransform: 'initial',

    '&.MuiButton-outlinedPrimary': {
      backgroundColor: 'white',
    },
  },
  noBorderRadiusLeft: {
    '& .MuiInputBase-input': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  noBorderRadius: {
    '& .MuiInputBase-input': {
      borderRadius: 0,
    },
  },

  loginPageInput: {
    '& .MuiInputBase-input': {
      padding: '6px 12px',
      height: 40,
    },
  },
  // background for start adornment
  grayBackground: {
    background: theme.palette.background.default,
    borderRight: '1px solid rgba(0, 0, 0, 0.23)',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  multiSelectPaddingEnhance: {
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '4px 32px 4px 8px',
    },
  },
  multiselect: {
    '& .MuiSelect-select': {
      padding: '4px 32px 4px 8px',
      height: inputHeights,
      boxSizing: 'border-box',

      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& fieldset': {
      boxShadow: '0 2px 6px 0 rgba(103, 103, 139, 0.1)',
    },
  },
  // chips used inside multi select
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  chip: {
    margin: 2,
  },

  multiSelectChip: {
    '& .MuiChip-label': {
      fontSize: 12,
    },
    '&.MuiChip-sizeSmall': {
      height: 22,
      margin: '0px 2px',
    },
  },

  noDataText: {
    color: theme.palette.text.disabled,
  },

  selectMenuItem: {
    fontSize: 12,
    padding: '4px 12px',
  },
  disabledDatePicker: {
    '& .MuiInputBase-root, .MuiInputBase-input': {
      cursor: 'not-allowed !important',
    },
  },
  datePickerAdornment: {
    marginRight: 0,
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #c4c4c4',
    paddingLeft: 2,
  },
  clearPickerAdornment: {
    marginRight: 0,
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fill: '#BABDCC',
    },
    '&:hover': {
      '& svg': {
        fill: '#969696',
      },
    },
  },
})

export default fieldStyle
